export default async function resolve(request) {
  const response = await request
    .catch(() => false)
    .then((res) => {
      if (!res) return false;
      if (res.data.error) return false;

      return res.data;
    });

  return response;
}
