<template>
  <div
    :class="['tw-flex tw-flex-nowrap tw-items-center tw-py-4 h-56 top-nav tw-border-b', { 'top-nav--bg' : !bgTransparent } ]"
  >
    <div ref="topnav-left-action">
      <slot name="left-action">
        <BackButton v-if="isBack && (!isDesktopView || showBackButton)" class="top-nav__action tw-mr-4 pl-body" :prev-route="prevRoute"/>
        <button
          v-else-if="type === 'menu'"
          class="top-nav__action tw-mr-4 pl-body"
          v-show="!isDesktopView"
          @click="clickLeftAction()"
        >
          <slot name="left-action-icon">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M68.7,13.1H3.3c-2,0-3.3-1.3-3.3-3.3s1.3-3.3,3.3-3.3h65.5c2,0,3.3,1.3,3.3,3.3S70.7,13.1,68.7,13.1z M72,36
                c0-2-1.3-3.3-3.3-3.3H3.3C1.3,32.7,0,34,0,36s1.3,3.3,3.3,3.3h65.5C70.7,39.3,72,38,72,36z M72,62.2c0-2-1.3-3.3-3.3-3.3H3.3
                c-2,0-3.3,1.3-3.3,3.3c0,2,1.3,3.3,3.3,3.3h65.5C70.7,65.5,72,64.1,72,62.2z"/>
            </svg>
          </slot>
        </button>
      </slot>
    </div>

    <p
      ref="topnav-title"
      :style="`
        transform: translateX(${(centerTitle && isMobileView) || alwaysCenter ? '-50%': '0'});
        top: ${(centerTitle && isMobileView) || alwaysCenter ? '16px': 'auto'};
        `"
      :class="[
        'tw-font-thin leading-24 tw-text-base tw-text-black tw-uppercase tw-truncate',
        titleClass,
        {
          'tw-mx-auto tw-text-center tw-font-normal tw-absolute tw-left-1/2': (centerTitle && isMobileView) || alwaysCenter
        },
        {
          'tw-flex tw-items-center': !logo
        },
      ]"
    >

      <svg v-if="logo" width="203" height="40" viewBox="0 0 203 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M104.41 33.5086C103.123 33.5086 102.07 32.4555 102.07 31.1685V24.8268C102.07 20.6614 100.993 18.5788 98.3722 18.5788C97.0384 18.5788 93.8559 19.1638 93.0836 20.287C92.522 21.1061 92.2412 22.7441 92.2412 25.1778V31.1685C92.2412 32.4555 91.1882 33.5086 89.9011 33.5086C88.6141 33.5086 87.561 32.4555 87.561 31.1685V22.4165C87.561 22.4165 87.5376 20.4976 88.2162 19.1404C88.8013 17.9703 89.5969 16.6365 91.118 15.8877C92.873 15.0218 94.2303 14.2964 99.0977 14.2964C100.174 14.2964 101.251 14.5304 102.327 15.0218C103.403 15.5132 104.269 16.1685 104.971 16.9875C106.165 18.4384 106.75 20.6614 106.75 23.61V31.1451C106.75 32.4555 105.697 33.5086 104.41 33.5086Z" fill="#59132C"/>
        <path d="M122.125 18.4617H118.896C118.311 18.4617 117.843 18.9297 117.843 19.5148L117.773 28.0093C117.773 28.0327 117.773 28.0561 117.773 28.0795L117.89 29.9048C118.007 31.8705 116.439 33.5085 114.497 33.5085H114.45C113.888 33.5085 113.42 33.0639 113.397 32.5257L113.21 29.4134C113.21 29.39 113.21 29.3666 113.21 29.3432V19.5382C113.21 18.9531 112.742 18.4851 112.157 18.4851H108.787C108.576 18.4851 108.389 18.3213 108.389 18.0873V16.5662C108.389 15.3494 109.395 14.3432 110.612 14.3432H112.157C112.718 14.3432 113.186 13.8751 113.21 13.3135L113.327 8.72693C113.327 8.70353 113.327 8.68012 113.327 8.65672L113.467 7.04206C113.584 5.70821 114.684 4.70197 116.018 4.70197H117.001C117.609 4.70197 118.1 5.24019 118.054 5.84861L117.749 9.35875C117.749 9.38215 117.749 9.40555 117.749 9.42895L117.703 13.2667C117.703 13.8517 118.171 14.3432 118.756 14.3432H122.125C122.71 14.3432 123.178 14.8112 123.178 15.3962V17.4321C123.202 17.9937 122.71 18.4617 122.125 18.4617Z" fill="#59132C"/>
        <path d="M144.005 23.8674C144.005 26.5819 143.092 28.8752 141.267 30.7239C139.442 32.5726 137.172 33.4852 134.434 33.4852C131.789 33.4852 129.52 32.5492 127.647 30.6771C125.752 28.805 124.816 26.5585 124.816 23.9376C124.816 21.2699 125.752 19.0234 127.624 17.1279C129.496 15.2559 131.766 14.3198 134.434 14.3198C137.101 14.3198 139.348 15.2559 141.22 17.1045C143.092 18.9532 144.005 21.2231 144.005 23.8674ZM139.652 23.9844C139.652 22.4868 138.903 20.9423 138.037 19.7722C137.195 18.6256 135.674 18.2746 134.434 18.2746C133.17 18.2746 131.649 18.6022 130.783 19.7488C129.917 20.8955 129.192 22.4399 129.192 23.9376C129.192 25.4821 129.917 27.0265 130.76 28.1264C131.602 29.2262 133.147 29.5538 134.434 29.5538C135.697 29.5538 137.218 29.2262 138.061 28.1264C138.903 27.0265 139.652 25.4821 139.652 23.9844Z" fill="#59132C"/>
        <path d="M158.56 16.3791C158.536 17.2917 158.232 18.2044 156.968 18.4852C154.067 19.1404 152.639 19.7956 152.639 22.6272V30.5835C152.639 32.4556 151.118 34 149.223 34C148.17 34 147.304 33.1342 147.304 32.0811C147.304 32.0811 147.304 23.1186 147.304 21.6911C147.304 20.2637 147.772 18.3916 149.714 16.4259C150.58 15.5367 151.75 15.0219 153.154 14.6942C153.762 14.5538 155.588 14.3198 156.266 14.3198C157.6 14.3198 158.583 14.9985 158.56 16.2153V16.3791Z" fill="#59132C"/>
        <path d="M200.377 33.5086H199.628C198.95 33.5086 198.388 32.947 198.388 32.2683V30.7239H198.318C197.733 31.6365 196.914 32.3151 195.884 32.8066C194.855 33.2746 193.731 33.532 192.585 33.532C189.987 33.532 187.109 32.5959 185.354 30.7005C183.599 28.805 182.71 26.5585 182.71 23.961C182.71 22.0187 183.201 20.2403 184.207 18.649C185.214 17.0577 186.594 15.8643 188.349 15.092C189.519 14.5772 191.391 14.3198 192.585 14.3198C193.591 14.3198 194.527 14.4134 195.369 14.7644C197.639 15.7005 197.803 14.2964 197.803 12.9158L197.335 5.73167C197.312 4.46802 197.897 4 198.575 4L199.909 4.23401C201.009 4.46802 201.664 5.66147 201.898 6.69111C201.898 6.69111 202.6 10.7863 202.6 18.2044L202.366 31.5195C202.343 32.6193 201.477 33.5086 200.377 33.5086ZM196.352 28.0796C197.382 26.9563 197.967 25.2949 197.827 23.7972C197.71 22.3931 197.195 21.1061 196.563 20.3105C195.861 19.4212 194.527 18.6256 192.725 18.4618C191.274 18.4618 189.613 18.8128 188.583 19.9126C187.53 21.0359 187.015 22.2995 187.015 23.7504C187.015 25.2949 187.507 26.6287 188.513 27.7988C189.519 28.9454 191.485 29.5304 193.029 29.5304C194.527 29.5304 195.323 29.2028 196.352 28.0796Z" fill="#59132C"/>
        <path d="M179.457 23.6568C179.457 24.8736 178.498 25.833 177.304 25.833H166.025C165.37 25.833 164.949 26.5819 165.323 27.1201C166.353 28.7114 167.827 29.5304 169.699 29.5304C171.15 29.5304 172.765 29.0858 173.607 28.2199C174.215 27.5881 175.058 27.2371 175.947 27.2371H177.07C177.983 27.2371 178.545 28.2199 178.077 29.0156C177.445 30.0686 176.602 30.9579 175.526 31.7301C173.888 32.9001 171.618 33.5086 169.699 33.5086C167.008 33.5086 164.761 32.5959 162.936 30.7472C161.111 28.8986 160.198 26.6521 160.198 23.961C160.198 21.2465 161.111 18.9766 162.913 17.1045C164.715 15.2324 166.985 14.2964 169.676 14.2964C172.297 14.2964 174.964 15.209 176.766 17.0109C178.568 18.8362 179.457 21.0359 179.457 23.6568ZM174.613 22.3697C174.847 22.3697 175.011 22.1591 174.964 21.9251C174.707 20.8486 174.192 19.9828 173.443 19.3276C172.577 18.6022 171.033 18.2511 169.722 18.2511C168.435 18.2511 167.336 18.6022 166.423 19.3276C165.814 19.819 165.346 20.4508 164.995 21.1997C164.761 21.7379 165.159 22.3697 165.768 22.3697H174.613Z" fill="#59132C"/>
        <path d="M84.2613 23.6568C84.2613 24.8736 83.3018 25.833 82.1084 25.833H70.8291C70.1739 25.833 69.7527 26.5819 70.1271 27.1201C71.1568 28.7114 72.631 29.5304 74.5031 29.5304C75.954 29.5304 77.5686 29.0858 78.411 28.2199C79.0195 27.5881 79.8619 27.2371 80.7511 27.2371H81.8744C82.787 27.2371 83.3486 28.2199 82.8806 29.0156C82.2488 30.0686 81.4064 30.9579 80.3299 31.7301C78.6919 32.9001 76.422 33.5086 74.5031 33.5086C71.812 33.5086 69.5655 32.5959 67.7402 30.7472C65.915 28.8986 65.0023 26.6521 65.0023 23.961C65.0023 21.2465 65.915 18.9766 67.7168 17.1045C69.5187 15.2324 71.7886 14.2964 74.4797 14.2964C77.1006 14.2964 79.7683 15.209 81.5702 17.0109C83.372 18.8362 84.2613 21.0359 84.2613 23.6568ZM79.4173 22.3697C79.6513 22.3697 79.8151 22.1591 79.7683 21.9251C79.5109 20.8486 78.9961 19.9828 78.2472 19.3276C77.3814 18.6022 75.837 18.2511 74.5265 18.2511C73.2394 18.2511 72.1396 18.6022 71.227 19.3276C70.6185 19.819 70.1505 20.4508 69.7995 21.1997C69.5655 21.7379 69.9633 22.3697 70.5717 22.3697H79.4173Z" fill="#59132C"/>
        <path d="M19.259 23.6568C19.259 24.8736 18.2995 25.833 17.1061 25.833H5.82683C5.17161 25.833 4.75039 26.5819 5.1248 27.1201C6.15445 28.7114 7.6287 29.5304 9.50078 29.5304C10.9516 29.5304 12.5663 29.0858 13.4087 28.2199C14.0172 27.5881 14.8596 27.2371 15.7488 27.2371H16.8721C17.7847 27.2371 18.3463 28.2199 17.8783 29.0156C17.2465 30.0686 16.4041 30.9579 15.3276 31.7301C13.6895 32.9001 11.4197 33.5086 9.50078 33.5086C6.80967 33.5086 4.56318 32.5959 2.73791 30.7472C0.912636 28.8986 0 26.6287 0 23.961C0 21.2465 0.912637 18.9766 2.71451 17.1045C4.51638 15.2324 6.78627 14.2964 9.47738 14.2964C12.0983 14.2964 14.766 15.209 16.5679 17.0109C18.3697 18.8362 19.259 21.0359 19.259 23.6568ZM14.415 22.3697C14.649 22.3697 14.8128 22.1591 14.766 21.9251C14.5086 20.8486 13.9938 19.9828 13.2449 19.3276C12.3791 18.6022 10.8346 18.2511 9.52418 18.2511C8.23713 18.2511 7.13729 18.6022 6.22465 19.3276C5.61622 19.819 5.14821 20.4508 4.79719 21.1997C4.56318 21.7379 4.961 22.3697 5.56942 22.3697H14.415Z" fill="#59132C"/>
        <path d="M49.5547 40H34.4453C27.5693 40 22 34.4289 22 27.5635V12.4492C22 5.57107 27.5693 0 34.4453 0H49.5547C56.4307 0 62 5.57107 62 12.4492V27.5635C61.9873 34.4289 56.418 40 49.5547 40Z" fill="#F8F9FF"/>
        <path d="M46.8604 26.3656C46.7212 26.0408 46.6832 25.6785 46.7718 25.3411C47.8097 20.8434 49.8221 11.673 49.8221 11.673C49.8221 11.673 47.9995 17.1078 46.8478 20.2062L43.2152 30.0513C42.9874 30.6759 42.3799 31.0882 41.709 31.0882H40.6079L36.9247 20.3936C35.8489 17.2327 34.2288 11.673 34.2288 11.673C34.2288 11.673 35.5578 20.631 36.0641 25.8034C36.102 26.1282 36.0641 26.4656 35.9881 26.7904L35.1654 29.8264C34.9376 30.6509 34.1782 31.2382 33.3049 31.2382H30.3811L31.8746 10.1488C31.9252 9.37416 32.5834 8.77446 33.3681 8.77446H35.0009C35.4565 8.77446 35.8995 8.96186 36.2033 9.29919L40.1522 13.622C40.3041 13.7844 40.418 13.9843 40.4813 14.1967C41.1015 16.4456 41.5698 21.9928 41.785 25.0663C41.7976 25.2537 42.0761 25.2537 42.0887 25.0663C42.3545 21.7929 42.9368 15.7085 43.6709 13.672L47.9489 9.2867C48.278 8.94937 48.721 8.76196 49.1893 8.76196H50.7208C51.5561 8.76196 52.2396 9.39914 52.2902 10.2237L53.6192 31.2132H50.0879C49.4171 31.2132 48.8222 30.8259 48.5564 30.2137L46.8604 26.3656Z" fill="#59132C"/>
      </svg>

      <!-- <img v-if="logo" :src="appLogoUrl" alt="eMentored" class="tw-h-14 tw-block"> -->

      <template v-else>
        <slot name="title-left" />
        {{ transformedTitle }}
        <slot name="title-right" />
      </template>
    </p>

    <div
      ref="topnav-right-action"
      class="tw-ml-auto tw-flex tw-items-center"
    >
      <slot name="right-actions" />
      <NotificationIcon
        v-show="showNotificationIcon"
        :class="[
          'tw-h-6 tw-w-6',
          { 'tw-mr-6': showUserPhoto },
          { 'mr-body': !showUserPhoto },
        ]"
      />
      <router-link
        v-if="showUserPhoto"
        :to="{ name: 'profile' }"
        tag="button"
        class="mr-body tw-cursor-pointer tw-rounded-full tw-flex tw-flex-grow-0"
      >
        <UserPhoto photo-size="tw-h-6 tw-w-6" self />
      </router-link>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import BackButton from '@/components/BackButton.vue';
import NotificationIcon from '@/components/NotificationIcon.vue';

export default {
  name: 'TopNav',
  components: {
    BackButton,
    NotificationIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    bgTransparent: {
      type: Boolean,
      default: false,
    },
    alwaysCenter: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: [String, Object, Array],
      default: '',
    },
    shrinkTitle: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'menu',
    },
    showNotificationIcon: {
      type: Boolean,
      default: true,
    },
    showUserPhoto: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    prevRoute: {
      type: [String, Object],
      default: undefined,
    },
  },
  watch: {
    title(val, oldVal) {
      this.titleSize = val.length;
      this.calculateTitleSize();
      if (!oldVal.length) {
        this.addOnResizeFn(this.calculateTitleSize);
      }
    },
  },
  data() {
    return {
      titleSize: this.title.length,
    };
  },
  computed: {
    isBack() {
      return this.type === 'back' && window.history.length > 1;
    },
    transformedTitle() {
      if (this.isMobileView && this.shrinkTitle) {
        return `${this.title.substring(0, ((this.titleSize > 4) ? this.titleSize : 4))}${(this.titleSize > 0 && this.title.length > this.titleSize) ? '...' : ''}`;
      }
      return this.title;
    },
  },
  methods: {
    clickLeftAction() {
      this.$emit('input', 'left-action');
    },
    /**
     * Truncates the title text by the space available for the title
     */
    // eslint-disable-next-line func-names
    calculateTitleSize: _debounce(async function () {
      const minTitleWidth = 40; // assumes profile photo width 24px + a margin of 16px

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();

      if (!this.$refs['topnav-title']) return;

      const $parentElWidth = this.$refs['topnav-title'].parentElement.clientWidth;
      const $titleElWidth = this.$refs['topnav-title'].clientWidth;
      const $siblingsWidth = (this.$refs['topnav-left-action'].clientWidth || 0) + (this.$refs['topnav-right-action'].clientWidth || 0) + 4; // small padding
      const spaceLeft = $parentElWidth - ($siblingsWidth + $titleElWidth); // 24 as the margin between siblings
      const letterWidth = Math.ceil(($titleElWidth - minTitleWidth) / this.transformedTitle.length);
      // console.log($parentElWidth, $titleElWidth, this.$refs['topnav-left-action'].clientWidth, this.$refs['topnav-right-action'].clientWidth);

      // resize the title length
      if (spaceLeft <= 0) {
        // reduce the title length
        const removedLettersCount = Math.abs(spaceLeft) / letterWidth;
        // console.log(Math.ceil(removedLettersCount), spaceLeft, letterWidth, this.titleSize, this.titleSize - Math.ceil(removedLettersCount));

        this.titleSize -= Math.ceil(removedLettersCount);
      } else if (spaceLeft > 10) {
        // grow the title length to the maximum size possible
        const addLettersCount = Math.ceil(Math.abs(spaceLeft) / letterWidth);
        this.titleSize = addLettersCount > this.title.length ? this.title.length : addLettersCount;
      }
    }, 1000),
  },
  // eslint-disable-next-line func-names
  mounted: _debounce(async function () {
    this.titleSize = this.title.length || 0;

    await this.$nextTick();
    await this.$nextTick();


    if (this.title.length > 0) {
      // console.log('resizer handler...');
      this.calculateTitleSize();
      this.addOnResizeFn(this.calculateTitleSize);
    }
  }, 500),
};
</script>

<style>
</style>
