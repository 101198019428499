<template>
  <div class="tw-mb-2 tw-block">
    <slot name="input-label">
      <label class="tw-block tw-text-sm tw-mb-2 opacity-78 tw-text-left" :for="name" :aria-label="name">
        {{ label }}
      </label>
    </slot>
    <label v-if="instruct.length > 0" class="tw-block tw-text-left tw-text-xs tw-mb-2 tw--mt-3 opacity-54">
      {{ instruct }}
    </label>

    <quillEditor
      :id="id"
      :placeholder="placeholder"
      :rows="rows"
      v-model="inputValue"
      :value="value"
      :class="inputClasses"
      :max-height="maxHeight"
      :options="editorOptions"
    />
      <!-- :name="name" -->
    <slot name="error-message">
      <p class="tw-text-red-500 tw-text-xs tw-italic tw-text-left" v-if="showError" slot> {{ error }} </p>
    </slot>
  </div>
</template>

<script>
// import BaseTextarea from '@/components/BaseTextarea.vue';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'QuillTextareaGroup',
  components: {
    // BaseTextarea,
    quillEditor,
  },
  props: {
    rows: {
      type: [String, Number],
      default: 1,
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    instruct: {
      type: String,
      default: '',
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    value: {
      default: undefined,
    },
    inputClasses: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
      editorOptions: {
        modules: {
          toolbar: ['bold', 'italic', 'underline'],
        },
      },
    };
  },
  computed: {
    showError() {
      return this.error.length > 0;
    },
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.inputValue = val;
    },
  },
};
</script>

<style>
/* purgecss start ignore */
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';
/* purgecss end ignore */
</style>
