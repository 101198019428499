import { apiStore } from '../modules/apiHelper';

export default {
  state: () => ({
    name: undefined,
    email: undefined,
    status: undefined,
  }),
  mutations: {
    updateAuthData(state, data) {
      state.name = data.name;
      state.email = data.email;
      state.status = data.status || undefined;

      apiStore.setItem('auth', {
        name: state.name,
        email: state.email,
        status: state.status,
      });
    },
    updateAuthStatus(state, error = undefined) {
      state.status = error;
    },
    clearAuthData(state) {
      state.name = undefined;
      state.email = undefined;
      state.status = undefined;
      apiStore.removeItem('auth');
    },
  },
  actions: {
    // uses sessionData
    async updateAuthData(context) {
      const data = await apiStore.getItem('auth') || {};

      context.commit('updateAuthData', data);
    },
  },
};
