var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'notification-bell tw-relative tw-fill-current new-notification',
    { 'tw-text-blue-500': _vm.newNotificationCount > 0 },
    { 'new-notification--bell': _vm.hasNewNotification },
    { 'tw-text-gray-800': _vm.newNotificationCount === 0 },
  ],on:{"click":function($event){$event.preventDefault();return _vm.onIconClick()}}},[_vm._t("icon",function(){return [_c('svg',{staticClass:"tw-w-full tw-h-full",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M45.4,63.5c0,4.7-3.9,8.5-8.5,8.5s-8.5-3.9-8.5-8.5c0-1.2,0.8-2,2-2c1.2,0,2,0.8,2,2c0,2.4,2,4.5,4.5,4.5s4.5-2,4.5-4.5\n        c0-1.2,0.8-2,2-2C44.6,61.5,45.4,62.3,45.4,63.5z M63.1,55.8c-0.6,0.8-1.6,1.4-2.8,1.4H11.9c-1,0-2-0.4-2.8-1.4c-0.6-0.8-1-2-0.8-3\n        c1-5.7,3.9-10.7,7.9-14.8c2.2-2.2,3.4-5.3,3.4-8.3v-5.9c0-4.7,2-9.3,5.7-12.4c2.4-2,5.5-3.4,8.5-3.9c0,0,0,0,0-0.2V2\n        c0-1.2,0.8-2,2-2s2,0.8,2,2v5.5c0,0,0,0,0,0.2c0.2,0,0.4,0,0.6,0c7.7,1.2,13.6,8.3,13.6,16.6v5.3c0,3,1.2,6.1,3.4,8.3\n        c4.1,4.1,6.9,9.1,7.9,14.8C64.1,53.7,63.7,54.8,63.1,55.8z M59.6,52.9c-1-4.7-3.2-8.9-6.7-12.2c-3-3-4.7-7.1-4.7-11.4v-5.3\n        c0-6.3-4.5-11.6-10.1-12.6C34.7,11,31,12,28.2,14.4c-2.6,2.4-4.3,5.7-4.3,9.3v5.7c0,4.3-1.8,8.3-4.7,11.4c-3.4,3.4-5.7,7.7-6.7,12.2\n        H59.6z"}})])]}),_c('NotificationPill',{class:[
      'tw-absolute tw--mt-2 tw-px-2 tw--mr-2 tw-right-0 tw-top-0 ',
      { 'tw-bg-blue-500 tw-text-white tw-shadow': _vm.newNotificationCount > 0 },
    ],attrs:{"total":_vm.newNotificationCount,"dumb":""}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }